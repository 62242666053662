<template>
  <div class="wrapper">
    <div class="container">
      <main class="row">
        <!-- AD Banner -->
        <ad-box :source="banner" @click="selectPromotionHandler"></ad-box>
        <!-- 訂單狀況 -->
        <div class="panel panel-default align center divider horizontal">
          <component
            :is="renderMatchedComponent(paymentType)"
            :order="order"
            :payment-method="paymentType"
            :is-mobile="false"></component>
          <!-- 防詐騙提示-->
          <avoid-fraud :is-mobile="false"></avoid-fraud>
          <!-- hamiPay 綁定 Modal -->
          <modal-cht-bind v-if="isBindChtAccount"></modal-cht-bind>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import finishedMethods from '@/mixins/cart/finished/finished-methods';
import { defaultOrder, defaultPaymentType } from '@/components/common/cart-finished-constant';
import { renderState } from '@/helpers/vuex';
import { createPromotionItem, sendGA4sEvent } from '@/helpers/tracking/ga';
import adBox from '@/components/desktop/finished/ad-1200-112.vue';
import atm from '@/components/desktop/finished/atm.vue';
import authFail from '@/components/desktop/finished/auth-fail.vue';
import modalChtBind from '@/components/common/finished/modal-cht-bind.vue';
import ccr from '@/components/desktop/finished/ccr.vue';
import avoidFraud from '@/components/common/avoid-fraud.vue';
import others from '@/components/common/finished/others.vue';

const data = function () {
  return {
    order: defaultOrder,
    paymentType: defaultPaymentType,
  };
};

const components = {
  atm,
  ccr,
  adBox,
  avoidFraud,
  authFail,
  modalChtBind,
  others,
};

const computed = {
  isBindChtAccount() {
    const bindChtAccount = renderState('OrderFinishInfo', 'bindChtAccount');
    return bindChtAccount.length > 0;
  },
  banner() {
    return this.$store.state.OrderFinishInfo.banners[0] || {};
  },
};

export default {
  name: 'cart-finished',
  data,
  components,
  computed,
  mixins: [finishedMethods],
  mounted() {
    this.$store.dispatch('Loading/updateLoading', { isLoading: false });
    this.$store.dispatch('OrderFinishInfo/fetchBanners', 'OA');
  },
  methods: {
    renderMatchedComponent(paymentType) {
      switch (paymentType) {
        case 'atm':
          return atm;
        case 'auth-fail':
          return authFail;
        case 'ccr':
          return ccr;
        default:
          return others;
      }
    },
    selectPromotionHandler(banner) {
      const parameterForGA = createPromotionItem(banner);
      sendGA4sEvent('select_promotion', parameterForGA);
    },
  },
};
</script>
