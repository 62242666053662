<template>
  <div>
    <h2 class="spacing top lg blue">訂單確認送出</h2>
    <p>
      感謝您此次的訂購，系統將會寄發繳費簡訊通知。
      <br />
      可持神腦生活APP繳費條碼或繳費簡訊，至各
      <a :href="`${baseUrl}/Location`" target="_blank" class="underline inverted"> 神腦特約服務中心 </a>
      完成繳費。
    </p>
    <p class="stackable">
      請於
      <b class="notice spacing sm">{{ order.expireDate }}</b>
      以前完成繳費，逾期訂單將自動失效。
    </p>
    <div class="bar gray w-fix-md has-inline divider horizontal text-center">
      <ul class="has-inline text-left sticky">
        <li>
          <span class="spacing right sm middle">訂單編號：</span>
          <b class="price fn-md middle">{{ order.id }}</b>
        </li>
        <li>
          <span class="spacing right sm middle">繳費金額：</span>
          <b class="price fn-md middle">{{ order.amount | dollar }}</b>
        </li>
        <li>
          <span class="spacing right sm middle">繳款期限：</span>
          <b class="price middle">{{ order.expireDate }}</b>
        </li>
      </ul>
    </div>
    <ul class="steps has-ccr-flow">
      <li class="steps-item">
        <i class="icon icon-store"></i>
        <span class="text">
          前往
          <a :href="`${baseUrl}/Location`" target="_blank" class="underline"> 神腦特約服務中心 </a>
        </span>
      </li>
      <li class="steps-item tooltip-hover">
        <i class="icon icon-phone-scan"></i>
        <span class="text">
          出示「
          <a href="javascript:void(0);" @click="openModal('CcrModal')" class="underline spacing sm">
            神腦生活APP繳費條碼
          </a>
          」或「繳費簡訊」
        </span>
      </li>
      <li class="steps-item">
        <i class="icon icon-money"></i>
        <span class="text">完成繳費</span>
      </li>
    </ul>
    <ccr-modal />
    <div class="bar text-ccr-tutorialmuted text-center">
      <ul class="has-inline text-left sticky">
        <li>其他提醒事項：</li>
        <li>1. 神腦特約服務中心繳費，付款方式可選擇現金、信用卡或行動支付工具，實際收款方式依門市現場說明為主。</li>
        <li>2. 神腦特約服務中心繳費完成後，若要取消訂單，需待訂單狀態為『已取消』方能協助退款。</li>
        <li>3. 發票將依神腦生活訂購流程為主，門市無法立即取得發票。</li>
        <li>
          4. 如欲查詢出貨進度，請至
          <a :href="orderUri" target="_blank" class="underline inverted"> 訂單查詢 </a>
        </li>
      </ul>
    </div>
    <h4 class="spacing bottom lg">再次感謝您的惠顧，謝謝！</h4>
    <!-- 繼續購物 | 訂單查詢 | 手機安心保 按鈕 -->
    <continue-bar :is-mobile="false"></continue-bar>
  </div>
</template>

<script>
import { dollar } from '@/helpers/format/dollar';
import { openModal } from '@/helpers/modal';
import { pcOrderListUri } from '@/components/common/cart-finished-constant';
import ccrModal from '@/components/desktop/finished/ccr-modal.vue';
import continueBar from '@/components/common/continue-buy-button-group.vue';

const url = process.env.VUE_APP_ONLINE;

const components = {
  continueBar,
  ccrModal,
};

const filters = {
  dollar,
};

const props = {
  order: Object,
};

const data = function () {
  return {
    orderUri: pcOrderListUri,
    baseUrl: url,
  };
};

export default {
  name: 'ccr',
  components,
  filters,
  props,
  data,
  methods: {
    openModal,
  },
};
</script>
