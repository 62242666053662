<template>
  <div>
    <div>
      <h2 class="spacing top lg blue">訂單確認送出</h2>
      <p class="spacing bottom">
        感謝您此次的訂購，系統已發出訂單確認通知信到您的Email信箱，您的訂單編號為
        <b class="notice">{{ order.id }}</b>
      </p>
      <p class="stackable">
        請於
        <b class="notice spacing fn-md">{{ order.expireDate }}</b>
        完成ATM轉帳程序，逾期訂單將自動失效
      </p>
      <div class="css-table bordered middle w-fix-md pull-center divider horizontal">
        <div class="css-tr">
          <div class="css-th">銀行代碼</div>
          <div class="css-th">轉帳帳號</div>
        </div>
        <div class="css-tr">
          <div class="css-td"><b class="notice">國泰世華 (代碼：013)</b></div>
          <div class="css-td">
            <p class="notice fn-lg spacing">
              <b class="notice">{{ order.account }}</b>
            </p>
            <p class="sub">(僅限此筆交易使用)</p>
          </div>
        </div>
      </div>
      <p>
        提醒您，完成付款後，如欲查詢出貨進度，請至
        <query-order-wording></query-order-wording>
        <br />
        如選擇「門市取貨」，商品到神腦門市後，系統將發訊息通知您前往門市取貨，請於七天內至門市完成取貨。
      </p>
      <h5 class="spacing bottom lg">再次感謝您的惠顧，謝謝！</h5>
    </div>
    <!-- 繼續購物 | 訂單查詢 按鈕 -->
    <continue-bar></continue-bar>
  </div>
</template>

<script>
import continueBar from '@/components/common/continue-buy-button-group.vue';
import queryOrderWording from '@/components/common/query-order-wording.vue';

const components = {
  continueBar,
  queryOrderWording,
};
const props = {
  order: Object,
};

export default {
  name: 'atm',
  props,
  components,
};
</script>

<style scoped></style>
