<template>
  <modal componentName="CcrModal" modalSizeCssClass="lg" bodyCssClasses="align left" :isHideFooter="true">
    <template v-slot:title>
      <h4 class="align left">神腦生活APP 繳費條碼說明</h4>
    </template>
    <template v-slot:body>
      <p>
        1. 開啟「神腦生活APP」
        <a
          href="https://helpcenter.senao.com.tw/event/20170101_app_renew/"
          target="_blank"
          class="underline inverted spacing left fn-sm">
          <i class="icon icon-info-circle icon-sm"></i>
          我要安裝「神腦生活APP」
        </a>
        <br />
      </p>

      <img src="/images/favicon/app-touch-icon.png" alt="神腦生活APP ICON" class="app-icon" />
      <p>
        2. 點擊
        <b class="inverted spacing sm">訂單查詢 > 訂單明細 > 繳費條碼</b>
        ，出示 QRcode 供門市刷讀
        <br />
      </p>
      <img src="/images/service/img-store-pay.png" alt="神腦生活繳費條碼說明圖" class="spacing bottom" />
    </template>
  </modal>
</template>

<script>
import Modal from '@/components/common/common-modal.vue';

const url = process.env.VUE_APP_ONLINE;

export default {
  name: 'ccr-modal',
  components: {
    Modal,
  },
  data() {
    return {
      baseUrl: url,
    };
  },
};
</script>
