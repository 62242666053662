<template>
  <div class="adbox" v-if="hasBanner">
    <a :href="`${source.url}${source.anchor}`" :target="source.action" @click="$emit('click', source)">
      <img :src="source.img" :alt="source.alt" />
    </a>
  </div>
</template>

<script>
const props = {
  source: {
    type: Object,
    default: {
      url: '',
      anchor: '',
      action: '',
      img: '',
      alt: '',
    },
  },
};

export default {
  name: 'ad-1200-112',
  props,
  computed: {
    hasBanner() {
      return Object.keys(this.source).length > 0;
    },
  },
};
</script>
